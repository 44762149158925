:root {
  --ys-primary: #{$primary};
  --ys-bg: #{$white};
  --ys-color: #{$dark};
  --ys-text-color: #{$dark};
  --ys-choice-bg: var(--ys-primary);
  --ys-choice-text: #{$white};
  --ys-choice-border: none;

  --ys-font-base: "Manrope";
  --ys-font-heading: "Manrope";
  --ys-font-sans: "Manrope";
  --ys-font-serif: "PT Serif";
  --ys-font-mono: "Roboto Mono";
  --ys-font-script: "Roboto Mono";

  // inputs
  --ys-input-font: var(--ys-font-base);
  --ys-input-color: var(--ys-text-color);
  --ys-input-bg: #fff;
  --ys-input-focus: var(--ys-primary);
  --ys-input-placeholder: #999;
  --ys-input-font-size:inherit;
  --ys-input-radius:0.375rem;
  --ys-input-padding:0.5rem 0.75rem;
  --ys-label-font: var(--ys-font-base);
  --ys-label-color: var(--ys-text-color);

  --ys-step-animation: "";
  --ys-step-animation-speed: 0;
  --ys-step-animation-delay: 0;

  --ys-component-fill: #{$dark};
  --ys-component-radius: 0px;
  --ys-component-padding: 0;
  --ys-component-animation: "";
  --ys-component-animation-speed: 0;
  --ys-component-animation-delay: 0;

  --ys-component-grid-cols: repeat(1, 1fr);
  --ys-component-grid-gap: 10px;
  --ys-component-font-scale: 100%;
  --ys-component-font-weight: normal;
  --ys-component-letter-spacing: none;
  --ys-component-line-height: auto;
  --ys-component-transition: none;
  --ys-component-filter: none;
  --ys-component-animation-hover: inherit;
  --ys-component-overflow : visible;

  // choices
  --ys-component-choice-margin: 10px;
  --ys-component-choice-padding: 10px;
  --ys-component-choice-radius: 0;
  --ys-component-choice-opacity: 1;
  --ys-component-text-align: left;
  --ys-component-flex-align: start;
  --ys-component-flex-justify: center;
  --ys-component-flex-direction: row;
  --ys-component-choice-bg: var(--ys-primary);
  --ys-component-choice-border: none;
  --ys-component-choice-color: var(--ys-choice-text);
  --ys-component-choice-hover-bg: var(--ys-primary);
  --ys-component-choice-hover-border: none;
  --ys-component-choice-hover-color: var(--ys-choice-text);
  --ys-component-choice-shadow: none;
  --ys-component-choice-text-shadow: none;
  --ys-component-choice-font: inherit;
  --ys-component-choice-font-scale: 100%;
  --ys-component-choice-font-weight: normal;
  --ys-component-choice-letter-spacing: none;
  --ys-component-choice-line-height: auto;

  --ys-component-choice-container-padding: auto;
  --ys-component-choice-container-bg: auto;
  --ys-component-choice-container-radius: auto;
  --ys-component-choice-container-blur: auto;
  --ys-component-choice-container-opacity: auto;

  --ys-component-outline: none;
  --ys-component-shadow: none;
  --ys-component-drop-shadow: none;
  --ys-component-text-shadow: none;
  --ys-component-svg-shadow: none;

  // buttons
  --ys-component-button-padding: 10px;
  --ys-component-button-radius: 0.25rem;
  --ys-component-button-align: center;
  --ys-component-button-bg: var(--ys-primary);
  --ys-component-button-border: none;
  --ys-component-button-color: var(--ys-choice-text);
  --ys-component-button-hover-bg: var(--ys-primary);
  --ys-component-button-hover-border: none;
  --ys-component-button-hover-color: var(--ys-choice-text);
  --ys-component-button-shadow: none;
  --ys-component-button-text-shadow: none;
  --ys-component-button-outline: none;
  --ys-component-button-outline-hover: none;
  --ys-component-button-font-size: inherit;
  --ys-component-button-letter-spacing:none;
  --ys-component-button-line-height:1.5;

  // quiz
  --ys-component-answer: var(--ys-text-color);
  --ys-component-answer-hover: #fff;
  --ys-component-answer-bg: #eee;
  --ys-component-answer-bg-hover: var(--ys-primary);
  --ys-component-answer-bg-correct:  #{$success};
  --ys-component-answer-color-correct:  #{$white};
  --ys-component-answer-bg-incorrect:  #{$danger};
  --ys-component-answer-color-incorrect:  #{$white};

  // tables
  --ys-component-th-bg-color: #333;
  --ys-component-th-color: #fff;
  --ys-component-tr-bg-color: #fff;
  --ys-component-tr-color: #666;
  --ys-component-tr-alt-bg-color: #f1f1f1;

  // hotspot
  --ys-component-icon-color: var(--ys-text-color);
  --ys-component-icon-bg: rgba(255, 255, 255, 0.4);

  //chat
  --ys-component-bubbleBg: #fff;
  --ys-component-bubbleColor: #333;
  --ys-component-optionBubbleBg: #fff;
  --ys-component-optionBubbleColor: #333;
  --ys-component-bubbleBg: var(--ys-primary);
  --ys-component-chat-position: "center";
  --ys-component-avatar-position: "center";
  --ys-component-divider-color: #ccc;

  // pick
  --ys-component-pick-item-text-color: #fff;
  --ys-component-pick-item-bg-color: rgba(0, 0, 0, 0.7);
  --ys-component-pick-item-hover-text-color: #fff;
  --ys-component-pick-item-hover-bg-color: rgba(0, 0, 0, 0.7);
  --ys-component-pick-item-picked-text-color: #fff;
  --ys-component-pick-item-picked-bg-color: rgba(0, 0, 0, 0.7);
  --ys-component-pick-item-picked-outline: 3px solid var(--ys-primary) !important;
  --ys-component-pick-item-font: inherit;

  //inventory
  --ys-inventory-text-color: #333;
  --ys-inventory-bg-color: #fff;
  --ys-inventory-divider-color: #efefef;
  --ys-inventory-font: var(--ys-font-base);
  --ys-inventory-hide-icon: inline-block;
  --ys-inventory-radius: 0.5rem;
  --ys-inventory-title: "Inventory";
  --ys-inventory-bg-image: "";
  --ys-inventory-bg-image-position: "";
  --ys-inventory-bg-image-size: "";

  // modal
  --ys-modal-text-color: #333;
  --ys-modal-bg-color: #fff;
  --ys-modal-divider-color: #ccc;
  --ys-modal-font: var(--ys-font-base);
  --ys-modal-radius: 0.5rem;

  // alert
  --ys-alert-text-color: #333;
  --ys-alert-bg-color: #fff;
  --ys-alert-font: var(--ys-font-base);
  --ys-alert-radius: 0.5rem;
  --ys-alert-button-color: var(--ys-component-button-color);
  --ys-alert-button-bg: var(--ys-component-button-bg);
  --ys-alert-button-hover-color: var(--ys-component-button-hover-color);
  --ys-alert-button-hover-bg: var(--ys-component-button-hover-bg);

  // tooltip
  --ys-tooltip-text-color: #fff;
  --ys-tooltip-bg-color: #000;
  --ys-tooltip-font: var(--ys-font-base);
  --ys-tooltip-radius: 0.5rem;
  --ys-tooltip-padding: 0.5rem;
  --ys-tooltip-text-scale: "";

  // popover
  --ys-popover-text-color: #000;
  --ys-popover-bg-color: #fff;
  --ys-popover-font: var(--ys-font-base);
  --ys-popover-radius: 0.5rem;
}
