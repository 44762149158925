.rotate-device {
  display: none;
  background: black;
  height: 100vh;
  width: 100vw;
  z-index: 99999999;
  position: fixed;
  top: 0;
  left: 0;
  .phone {
    height: 50px;
    width: 100px;
    border: 3px solid white;
    border-radius: 10px;
    animation: rotate 1.5s ease-in-out infinite alternate;
    /* display: none; */
  }
  .message {
    color: white;
    font-size: 1em;
    margin-top: 40px;
    /* display: none; */
  }
}
@keyframes rotate {
  0% {
    transform: rotate(-90deg);
  }
  50% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@media only screen and (max-device-width: 390px) and (orientation: portrait) {
  .rotate-device {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .phone,
    .message {
      display: block;
    }
  }
}
