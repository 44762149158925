.ys-swal {
  font-family: var(--ys-alert-font);
  color: var(--ys-alert-text-color) !important;
  background: var(--ys-alert-bg-color) !important;
  // text-shadow: var(--ys-component-text-shadow) !important;
  border-radius: var(--ys-alert-radius);
  .btn-ys {
    color: var(--ys-alert-button-color);
    background-color: var(--ys-alert-button-bg);
  }
  .btn-ys:hover {
    color: var(--ys-alert-button-hover-color);
    background-color: var(--ys-alert-button-hover-bg);
  }
}
.swal2-container {
  z-index: 1099;
}
