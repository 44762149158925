.hotspot-container{
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    overflow:hidden;
    .hotspot-img{
        position: absolute;
        top:0;
        left:0;
    }
    .hotspot{
        position: absolute;
        background-color: var(--ys-component-icon-bg);
        width:50px;
        height:50px;
        border-radius: 50%;
        z-index:2;
        display: flex;
        align-items: center;
        justify-content: center;
        color:var(--ys-component-icon-color);
        font-size:2rem;
    }
}
.hotspot-icon-hover{
    .hotspot{
        cursor: pointer;
    }
}

.hotspot-draw-container{
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background-color:rgba(0,0,0,0.2);
    z-index:1;
}

.newHotSpot{
    position: absolute;
    z-index:101;
    background-color:rgba(255,255,255,0.2);
}

.instructions-container{
    background-color: #333;
    position: fixed;
    left: 50%;
    top: -50px;
    min-width: 750px;
    color: #fff;
    border-radius: 0.375rem;
    z-index: 10;
    transform: translateX(-50%);
    padding:1rem;
    font-size:1rem;
    line-height:1.2rem;
    text-align: center;
}
