@import "rotate-device";
a {
  color: var(--ys-primary);
}
.player-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
#ys-player {
  .component-wrap,
  .component {
    transition: var(--ys-component-transition);
  }
  .component-drag,
  .component-container {
    position: absolute;
  }
  .component-drag {
    // hack for the draging...
    transform: none !important;
    transition: left, right none !important;
  }
  .component-container-choices {
    pointer-events: none;
    .choice {
      pointer-events: auto;
    }
  }
}
.component-button,
.component-download {
  box-shadow: none !important;
  .btn-stretch {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: var(--ys-component-button-align);
    //text-align: var(--ys-component-button-align);
    font-weight: 900;
    font-size: var(--ys-component-font-scale);
    color: var(--ys-component-button-color);
    border-radius: var(--ys-component-button-radius);
    box-shadow: var(--ys-component-shadow);
    border: var(--ys-component-button-border);
    outline: var(--ys-component-button-outline);
    padding: var(--ys-component-button-padding);
    background-color: var(--ys-component-button-bg);
    text-decoration: none;
    gap: 0.25rem;
  }
  .btn-stretch:hover,
  .btn-stretch:focus {
    color: var(--ys-component-button-hover-color);
    border: var(--ys-component-button-hover-border);
    outline: var(--ys-component-button-hover-outline);
    background-color: var(--ys-component-button-hover-bg);
  }
  .btn-stretch:active {
    color: var(--ys-component-button-color);
    border: var(--ys-component-button-border);
    outline: var(--ys-component-button-outline);
    background-color: var(--ys-component-button-bg);
  }
}

#ys-player .component-wrap-choices {
  pointer-events: none;
}
.choices-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: var(--ys-component-flex-align);
  justify-content: var(--ys-component-flex-justify);
  z-index: 20;
  flex-direction: var(--ys-component-flex-direction);
  gap: var(--ys-component-choice-margin);
  padding: var(--ys-component-choice-container-padding);
  background-color: var(--ys-component-choice-container-bg);
  border-radius: var(--ys-component-choice-container-radius);
  opacity: var(--ys-component-choice-container-opacity);
  filter: var(--ys-component-choice-container-blur);

  .choice {
    pointer-events: all;
    font-family: var(--ys-component-choice-font);
    margin: var(--ys-component-choice-margin);
    padding: var(--ys-component-choice-padding);
    border-radius: var(--ys-component-choice-radius);
    opacity: var(--ys-component-choice-opacity);
    filter: blur(var(--ys-component-choice-blur));
    text-align: var(--ys-component-text-align);
    background-color: var(--ys-component-choice-bg);
    border: var(--ys-component-choice-border);
    color: var(--ys-component-choice-color);
    box-shadow: var(--ys-component-choice-shadow);
    text-shadow: var(--ys-component-choice-text-shadow);
    font-size: var(--ys-component-choice-font-scale);
    font-weight: var(--ys-component-choice-font-weight);
    line-height: var(--ys-component-choice-line-height);
    letter-spacing: var(--ys-component-choice-letter-spacing);
  }
  .choice:hover,
  .choice:focus {
    color: var(--ys-component-choice-hover-color);
    background-color: var(--ys-component-choice-hover-bg);
    border: var(--ys-component-choice-hover-border);
    font-family: var(--ys-component-choice-font);
    box-shadow: var(--ys-component-choice-shadow);
    line-height: var(--ys-component-choice-line-height);
    letter-spacing: var(--ys-component-choice-letter-spacing);
  }

  .btn.choice:active {
    color: var(--ys-component-choice-color);
    border: var(--ys-component-choice-border);
    outline: var(--ys-component-choice-outline);
    background-color: var(--ys-component-choice-bg);
  }
}
.component {
  .choices-container {
    .choice {
      // font-size: inherit !important;
      // letter-spacing: inherit !important;
    }
  }
  .ql-editor {
    // ql heading styles
    h1 {
      font-size: 2em;
    }
    h2 {
      font-size: 1.5em;
    }
    h3 {
      font-size: 1.17em;
    }
    h4 {
      font-size: 1em;
    }
    h5 {
      font-size: 0.83em;
    }
    h6 {
      font-size: 0.75em;
    }
  }
}

// speech bubble
.speech-bubble {
  background: var(--ys-component-bubbleBg);
  color: var(--ys-component-bubbleColor);
  padding: 20px;
  text-align: var(--ys-component-text-align);
  font-weight: 500;
  position: relative;
  border-radius: 0.25rem;
  text-shadow: var(--ys-component-text-shadow);
}
.speech-bubble {
  textarea {
    border: none;
    outline: none;
    display: block;
    width: 100%;
    background: var(--ys-component-bubbleBg);
    color: var(--ys-component-bubbleColor);
    text-align: var(--ys-component-text-align);
    font-weight: 500;
    position: relative;
    border-radius: 0.25rem;
    text-shadow: var(--ys-component-text-shadow);
    resize: none;
  }
}
.speech-bubble-sm {
  font-size: 1rem;
  padding: 1rem;
}
.speech-bubble-xs {
  font-size: 1rem;
  padding: 0.5rem;
  line-height: 1rem;
}
.chat-task .main-bubble-container .speech-bubble {
  margin: 10px auto;
}
.last-ask {
  opacity: 0.8;
  text-align: left;
  margin-bottom: 0.5rem;
  display: inline-block;
}

.sb-tr:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 10px solid var(--ys-component-bubbleBg);
  border-right: 10px solid transparent !important;
  border-top: 10px solid var(--ys-component-bubbleBg);
  border-bottom: 10px solid transparent !important;
  right: -19px;
  top: 6px;
}

.sb-tl:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 10px solid transparent !important;
  border-right: 10px solid var(--ys-component-bubbleBg);
  border-top: 10px solid var(--ys-component-bubbleBg);
  border-bottom: 10px solid transparent !important;
  left: -19px;
  top: 6px;
}

.sb-bl:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 10px solid var(--ys-component-bubbleBg);
  border-right: 10px solid transparent !important;
  border-top: 10px solid var(--ys-component-bubbleBg);
  border-bottom: 10px solid transparent !important;
  left: 19px;
  bottom: -19px;
}

.sb-br:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 10px solid transparent !important;
  border-right: 10px solid var(--ys-component-bubbleBg);
  border-top: 10px solid var(--ys-component-bubbleBg);
  border-bottom: 10px solid transparent !important;
  right: 19px;
  bottom: -19px;
}

.chat-choices {
  // speech bubble
  .speech-bubble {
    background: var(--ys-component-optionBubbleBg);
    color: var(--ys-component-optionBubbleColor);
  }

  .sb-tr:before {
    border-left: 10px solid var(--ys-component-optionBubbleBg);
    border-top: 10px solid var(--ys-component-optionBubbleBg);
  }

  .sb-tl:before {
    border-right: 10px solid var(--ys-component-optionBubbleBg);
    border-top: 10px solid var(--ys-component-optionBubbleBg);
  }

  .sb-bl:before {
    border-left: 10px solid var(--ys-component-optionBubbleBg);
    border-top: 10px solid var(--ys-component-optionBubbleBg);
  }

  .sb-br:before {
    border-right: 10px solid var(--ys-component-optionBubbleBg);
    border-top: 10px solid var(--ys-component-optionBubbleBg);
  }
}

// chat
.chat-container {
  height: 100%;
  width: 100%;
  display: block;
  .chat-character {
    display: flex;
    align-items: var(--ys-component-chat-position);
    width: 100%;
    height: 100%;
    justify-content: center;
  }
  .chat-speech {
    display: flex;
    align-items: stretch;
    flex-direction: column;
    flex: 1;
    .chat-speech-inner {
      padding: 1rem;
      width: 100%;
    }
  }
  .chat-avatar {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: var(--ys-component-avatar-position);
    height: 100%;
    .chat-avatar-inner {
      padding: 1rem;
      img {
        max-width: 100%;
        max-height: 100%;
        border-radius: var(--ys-component-avatarRadius);
      }
    }
    i {
      font-size: 14rem;
    }
  }
}
.chat-choices {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding-left: 40px;
  a {
    --ys-component-bg: rgb(243, 229, 176);
    text-decoration: none;
    color: $dark;
    cursor: pointer;
    margin: 0.25rem 0.25rem 0.25rem 1rem;
  }
}
.chat-container-r {
  flex-direction: row-reverse;
}

// charts
.chart-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.step-content-container {
  position: absolute;
  left: 50%;
  top: 50%;
}
.step-content {
  position: relative;
  width: 1024px;
  height: 576px;
  overflow: hidden;
  text-align: center;
  transform-origin: center center;
  margin: auto;
}
.step-content,
.global-content {
  .component-wrap {
    position: absolute;
  }
  .component-wrap-text,
  .component-wrap-quiz {
    font-size: 3rem;
  }
  .component-wrap-button {
    font-size: 1.5rem;
  }
  .component-choices {
    font-size: 1rem;
  }
  .component-wrap-pick,
  .component-wrap-tabs {
    font-size: 2rem;
  }
  .component {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    // do the vars here?
    font-family: var(--ys-font-base);
    color: var(--ys-color);
    outline: var(--ys-component-outline);
    box-shadow: var(--ys-component-shadow);
    text-shadow: var(--ys-component-text-shadow);

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: var(--ys-font-heading);
    }
    padding: var(--ys-component-padding);
    border-radius: var(--ys-component-radius);
    > img {
      border-radius: inherit;
    }
    > div > svg {
      overflow: visible;
      // shadow
      filter: var(--ys-component-svg-shadow);
    }
    .ql-editor {
      overflow: visible;
    }
    pre,
    code,
    kbd,
    samp {
      font-family: var(--ys-font-monospace);
    }
  }
  .component-shape,
  .component-drawing,
  .component-icon {
    > svg {
      overflow: visible;
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }
    div > svg {
      overflow: visible;
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }
  }

  .component-shape,
  .component-drawing,
  .component-icon {
    svg,
    path,
    circle,
    rect {
      overflow: visible;
      fill: var(--ys-component-fill) !important;
    }
  }
  .component-text {
    .ql-editor {
      z-index: 10;
    }
  }
  .component-shape {
    // overflow: hidden;
  }
}
.component-icon {
  svg,
  path,
  circle,
  rect {
    overflow: visible;
    fill: var(--ys-component-fill) !important;
  }
}
// quiz
.table-questions {
  td {
    border: none;
  }
}
.block-transform {
  z-index: 0;
  position: static;
}
#ys-player {
  .component-drag {
    cursor: move !important;
    .ql-editor * {
      cursor: move !important;
    }
  }
}

// inventory

.inventory-items {
  img {
    max-width: 100%;
  }

  .card-body h5 {
    font-size: 1rem;
  }
  .card-body p {
    font-size: 0.825rem;
  }
  .inventory-item-img-placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 150px;
    border-radius: $border-radius;
    i {
      font-size: 4rem;
      color: rgba($dark, 0.25);
    }
  }
  .inventory-item {
    .card-action-btns {
      display: none;
    }
    img.square {
      max-height: 170px;
      object-fit: cover;
      margin: 0 auto;
    }
  }
  .inventory-item:hover {
    .card-action-btns {
      display: flex;
    }
  }
}
.inventory-items-manager {
  .inventory-item {
    .card-action-btns {
      top: 50%;
      bottom: auto;
    }

    .card-img-top {
      height: 160px;
      object-fit: cover;
      margin: 0 auto;
    }
  }
}

// pick
.pick-task-content {
  overflow: scroll;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 100px);
  padding: var(--ys-component-padding);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .inventory-items-wrap {
    overflow: scroll;
    padding: 1rem 0;
    .inventory-items {
      padding: 10px;
      display: grid;
      grid-gap: 10px;
      grid-template-columns: repeat(7, auto);
      .inventory-item {
        max-height: 140px;
        max-width: 140px;
      }
    }
  }
}
.task-btns {
  text-align: center;
  z-index: 5;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: var(--ys-component-padding);
  background-color: var(--ys-component-background);
}
.pick-task-instructions {
  textarea {
    font-size: var(--ys-component-font-scale);
    line-height: var(--ys-component-line-height);
    border: none;
    outline: none;
    display: block;
    width: 100%;
    background: var(--ys-component-bubbleBb);
    text-align: var(--ys-component-text-align);
    font-weight: 500;
    position: relative;
    border-radius: 0.25rem;
    color: var(--ys-component-text-color);
    text-shadow: var(--ys-component-text-shadow);
    resize: none;
  }
  font-size: inherit;
  line-height: var(--ys-component-line-height);
  border: none;
  outline: none;
  display: block;
  width: 100%;
  text-align: var(--ys-component-text-align);
  font-weight: 500;
  position: relative;
  border-radius: 0.25rem;
  color: var(--ys-component-text-color);
  text-shadow: var(--ys-component-text-shadow);
  resize: none;
  margin-bottom: 0.25rem;
}
.item-picked {
  outline: var(--ys-component-pick-item-picked-outline) !important;
  .item-name {
    opacity: 1 !important;
  }
}

.inventory-items {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  width: 100%;
  .inventory-item {
    transition: all ease-in-out 0.1s;
    flex: 1 0 calc(16% - 10px);
    background-color: transparent;
    position: relative;
    display: flex;
    min-height: 80px;
    flex-grow: 0;
    overflow: hidden;
    border-radius: $border-radius;
    padding: 0;
    .item-name {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      background-color: var(--ys-component-pick-item-bg-color);
      color: var(--ys-component-pick-item-text-color);
      padding: 2px 5px;
      text-align: center;
      font-size: 0.875rem;
      opacity: 0;
    }
  }
  .inventory-item:hover {
    .item-name {
      transition: all ease-in-out 0.1s;
      opacity: 1;

      background-color: var(--ys-component-pick-item-hover-bg-color);
      color: var(--ys-component-pick-item-hover-text-color);
    }
  }
  .inventory-item-text {
    min-height: 10px;
    .item-name {
      position: relative;
      transition: all ease-in-out 0.1s;
      opacity: 1;
      font-size: 1rem;
    }
  }
  .item-picked {
    .item-name {
      background-color: var(--ys-component-pick-item-picked-bg-color);
      color: var(--ys-component-pick-item-picked-text-color);
    }
  }
}
.inventory-items-row {
}
.item-picker-popover {
  width: 50vw;
  max-width: none;
}
// controls
.controls-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  .btn {
    font-size: 1.5rem;
    padding: 0.5rem;
    color: var(--ys-component-text-color);
  }
  .btn:disabled,
  .btn:focus {
    border-color: transparent;
  }
}

.user-tracking-form {
  background-color: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 9991;
  padding: 1rem;
  border-radius: $border-radius;
  min-width: 50%;
}
.user-tracking-form-bg {
  background-color: rgba(255, 255, 255, 1);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  width: 100%;
  height: 100%;
  z-index: 999;
}

.component-yo-logo {
  .cls-1 {
    fill: var(--ys-component-text-color);
  }
}
.component-yo-logo a:hover {
  .cls-1 {
    fill: $primary;
  }
}

.component-table {
  .table {
    thead {
      background-color: var(--ys-component-th-bg-color);
      color: var(--ys-component-th-color);
    }
    tbody tr {
      background-color: var(--ys-component-tr-bg-color);
      color: var(--ys-component-tr-color);
      td {
        color: var(--ys-component-tr-color) !important;
      }
    }
  }
  .table-striped {
    tbody tr:nth-child(odd) {
      background-color: var(--ys-component-tr-alt-bg-color);
    }
  }
}

// custom animations

.task-obscure {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;

  animation: blur 0.25s ease-in-out;
  backdrop-filter: blur(8.7px);
  -webkit-backdrop-filter: blur(8.7px);
}
.task-screen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
}

@keyframes blur {
  0% {
    backdrop-filter: blur(0px);
    -webkit-backdrop-filter: blur(0px);
  }
  100% {
    backdrop-filter: blur(8.7px);
    -webkit-backdrop-filter: blur(8.7px);
  }
}
.btn-ys {
  font-family: var(--ys-component-button-font);
  justify-content: var(--ys-component-button-align);
  color: var(--ys-component-button-color);
  border-radius: var(--ys-component-button-radius);
  border: var(--ys-component-button-border);
  padding: var(--ys-component-button-padding);
  background-color: var(--ys-component-button-bg);
  box-shadow: var(--ys-component-button-shadow);
  text-shadow: var(--ys-component-button-text-shadow);
  font-size: var(--ys-component-button-font-size);
  letter-spacing: var(--ys-component-button-letter-spacing);
  line-height: var(--ys-component-button-line-height);
}
.btn-ys:hover {
  font-family: var(--ys-component-button-font);
  color: var(--ys-component-button-hover-color);
  border: var(--ys-component-button-hover-border);
  background-color: var(--ys-component-button-hover-bg);
}

.btn.btn-ys:active {
  color: var(--ys-component-button-color);
  border: var(--ys-component-button-border);
  outline: var(--ys-component-button-outline);
  background-color: var(--ys-component-button-bg);
}
.btn-ys:disabled {
  font-family: var(--ys-component-button-font);
  justify-content: var(--ys-component-button-align);
  color: var(--ys-component-button-color);
  border-radius: var(--ys-component-button-radius);
  border: var(--ys-component-button-border);
  padding: var(--ys-component-button-padding);
  background-color: var(--ys-component-button-bg);
}

.video-progress {
  transition: all ease-in-out 0.2s;
  position: absolute;
  bottom: 0px;
  left: 0;
  font-size: 4rem;
  background-color: var(--ys-primary);
  z-index: 999;
  width: 0;
  height: 5px;
}

.ql-size-small {
  font-size: 0.75em;
}
.ql-size-large {
  font-size: 1.5em;
}
.ql-size-huge {
  font-size: 2.5em;
}

.form-feedback {
  margin-bottom: 2rem;
}

.ql-align-center {
  text-align: center;
}
.ql-align-right {
  text-align: right;
}
.ql-align-left {
  text-align: left;
}

.ql-align-justify {
  text-align: justify;
}

.modal-body-slim {
  padding: 0 !important;
}
.btn-close-media {
  background-color: #fff;
  z-index: 99;
  position: absolute;
  right: -20px;
  top: -20px;
  padding: 10px;
  border-radius: 50%;
  opacity: 1 !important;
}
.component:focus-visible {
  outline: 4px solid $primary;
}
.component-image-shadow {
  filter: drop-shadow(var(--ys-component-drop-shadow));
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.component-image {
  overflow: hidden;
  // box-shadow: none !important;
  .image-crop {
    border-radius: inherit;
    padding: var(--ys-component-padding);
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
  }
}
.component-icon {
  img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
}

// inventory test..
.modal-content {
  background-color: #fff;
}

.inventory-wrap {
  .modal-content {
    font-family: var(--ys-inventory-font);
    color: var(--ys-inventory-text-color) !important;
    background-color: var(--ys-inventory-bg-color);
    border-radius: var(--ys-inventory-radius);
    background-image: var(--ys-inventory-bg-image);
    background-position: var(--ys-inventory-bg-image-position);
    background-size: var(--ys-inventory-bg-image-size);
    .modal-footer,
    .modal-header {
      border-color: var(--ys-inventory-divider-color) !important;
    }
    .modal-header h4 i {
      display: var(--ys-inventory-hide-icon) !important;
    }
  }
  .modal-header {
    border-top-left-radius: var(--ys-inventory-radius);
    border-top-right-radius: var(--ys-inventory-radius);
    .btn {
      color: var(--ys-inventory-text-color) !important;
      font-size: 2rem;
      line-height: 2rem;
      padding: 0;
      border: none !important;
    }
  }
  .modal-footer {
    border-bottom-left-radius: var(--ys-inventory-radius);
    border-bottom-right-radius: var(--ys-inventory-radius);
  }
}

.ys-modal {
  .modal-content {
    font-family: var(--ys-modal-font);
    color: var(--ys-modal-text-color) !important;
    background-color: var(--ys-modal-bg-color);
    border-radius: var(--ys-modal-radius);
    .modal-footer,
    .modal-header {
      border-color: var(--ys-modal-divider-color) !important;
    }
  }
  .modal-header {
    border-top-left-radius: var(--ys-modal-radius);
    border-top-right-radius: var(--ys-modal-radius);
    .btn {
      color: var(--ys-modal-text-color) !important;
      font-size: 2rem;
      line-height: 2rem;
      padding: 0;
      border: none !important;
    }
  }
  .modal-footer {
    border-bottom-left-radius: var(--ys-modal-radius);
    border-bottom-right-radius: var(--ys-modal-radius);
  }
}

// components
@import "chat-messenger";
@import "chat-adventure";
@import "tabs";
@import "360-image";

.ys-tooltip {
  .tooltip-inner {
    color: var(--ys-tooltip-text-color) !important;
    background-color: var(--ys-tooltip-bg-color) !important;
    font-family: var(--ys-tooltip-font) !important;
    padding: var(--ys-tooltip-padding) !important;
    font-size: var(--ys-tooltip-text-scale) !important;
    border-radius: var(--ys-tooltip-radius) !important;
  }
}

.tooltip-inverse {
  --bs-tooltip-bg: #eee;
  --bs-tooltip-color: #000;
}
.tooltip{
  font-size:0.75rem;
}
.tooltip.fade {
  transition: none;
  opacity: 0;
}
.tooltip.bottom {
  -webkit-animation: tooltipBottom 0.2s ease-in;
  -moz-animation: tooltipBottom 0.2s ease-in;
  animation: tooltipBottom 0.2s ease-in;
}

.tooltip.top {
  -webkit-animation: tooltipTop 0.2s ease-in;
  -moz-animation: tooltipTop 0.2s ease-in;
  animation: tooltipTop 0.2s ease-in;
}
.tooltip.end {
  -webkit-animation: tooltipRight 0.2s ease-in;
  -moz-animation: tooltipRight 0.2s ease-in;
  animation: tooltipRight 0.2s ease-in;
}
.tooltip.start {
  -webkit-animation: tooltipLeft 0.4s ease-in;
  -moz-animation: tooltipLeft 0.4s ease-in;
  animation: tooltipLeft 0.4s ease-in;
}

@-webkit-keyframes tooltipBottom {
  from {
    opacity: 0;
    margin-top: -10px;
  }
  to {
    opacity: 0.9;
    margin-top: 0;
  }
}
@-moz-keyframes tooltipBottom {
  from {
    opacity: 0;
    margin-top: -10px;
  }
  to {
    opacity: 0.9;
    margin-top: 0;
  }
}
@keyframes tooltipBottom {
  from {
    opacity: 0;
    margin-top: -10px;
  }
  to {
    opacity: 0.9;
    margin-top: 0;
  }
}

@-webkit-keyframes tooltipTop {
  from {
    opacity: 0;
    margin-bottom: -10px;
  }
  to {
    opacity: 0.9;
    margin-bottom: 0;
  }
}
@-moz-keyframes tooltipTop {
  from {
    opacity: 0;
    margin-bottom: -10px;
  }
  to {
    opacity: 0.9;
    margin-bottom: 0;
  }
}
@keyframes tooltipTop {
  from {
    opacity: 0;
    margin-bottom: -10px;
  }
  to {
    opacity: 0.9;
    margin-bottom: 0;
  }
}

@-webkit-keyframes tooltipRight {
  from {
    opacity: 0;
    margin-left: -10px;
  }
  to {
    opacity: 0.9;
    margin-left: 0;
  }
}
@-moz-keyframes tooltipRight {
  from {
    opacity: 0;
    margin-left: -10px;
  }
  to {
    opacity: 0.9;
    margin-left: 0;
  }
}
@keyframes tooltipRight {
  from {
    opacity: 0;
    margin-left: -10px;
  }
  to {
    opacity: 0.9;
    margin-left: 0;
  }
}

@-webkit-keyframes tooltipLeft {
  from {
    opacity: 0;
    margin-left: 10px;
  }
  to {
    opacity: 0.9;
    margin-left: 0;
  }
}
@-moz-keyframes tooltipLeft {
  from {
    opacity: 0;
    margin-left: 10px;
  }
  to {
    opacity: 0.9;
    margin-left: 0;
  }
}
@keyframes tooltipLeft {
  from {
    opacity: 0;
    margin-left: 10px;
  }
  to {
    opacity: 0.9;
    margin-left: 0;
  }
}
.popover {
  background-color: #fff;
}
.ys-popover {
  border-radius: var(--ys-popover-radius) !important;
  .popover-content {
    color: var(--ys-popover-text-color) !important;
    background-color: var(--ys-popover-bg-color) !important;
    font-family: var(--ys-popover-font) !important;
    border-radius: 0 0 var(--ys-popover-radius) var(--ys-popover-radius) !important;
  }
  .popover-body {
    padding: 0;
  }
  .popover-wrap {
    display: flex;
    flex-direction: column;
  }
  .popover-wrap-left {
    flex-direction: row;
    > div {
      flex-basis: 100%;
      img {
        height: 100%;
        object-fit: cover;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  .popover-wrap-right {
    flex-direction: row-reverse;
    align-items: stretch;
    > div {
      flex-basis: 100%;
      img {
        height: 100%;
        object-fit: cover;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  .popover-wrap-top {
    flex-direction: column-reverse;
  }
  .popover-wrap-bottom {
    flex-direction: column;
  }
}

.ys-popover.bs-popover-top .popover-arrow:after {
  border-top-color: var(--ys-popover-bg-color) !important;
}

.ys-popover.bs-popover-end .popover-arrow:after {
  border-right-color: var(--ys-popover-bg-color) !important;
}

.ys-popover.bs-popover-bottom .popover-arrow:after {
  border-bottom-color: var(--ys-popover-bg-color) !important;
}

.ys-popover.bs-popover-start .popover-arrow:after {
  border-left-color: var(--ys-popover-bg-color) !important;
}

.ys-popover-no-title {
  .popover-body {
    overflow: hidden;
    border-radius: var(--bs-popover-border-radius) !important;
  }
}
// forms
.form-container {
  display: grid;
  grid-template-columns: var(--ys-component-grid-cols);
  grid-column-gap: var(--ys-component-grid-gap);
  grid-row-gap: var(--ys-component-grid-gap);
  .form-control,
  .form-select {
    background-color: var(--ys-input-bg);
    color: var(--ys-input-color);
    font-family: var(--ys-input-font);
    font-size:var(--ys-input-font-size);
  }
  .form-control{
    border-radius:var(--ys-input-radius);
    padding:var(--ys-input-padding);
  }

  .form-control:focus,
  .form-select:focus {
    border-color: var(--ys-input-focus);
    box-shadow: inset 0 0 0 rgba(0, 0, 0, 0),
      0 0.5rem 1.125rem -0.5rem rgba(0, 0, 0, 0.2);
  }

  .form-control::placeholder,
  .form-select::placeholder {
    color: var(--ys-input-placeholder);
    opacity: 1; /* Firefox */
  }

  .form-control::-ms-input-placeholder,
  .form-control::-ms-select-placeholder {
    /* Edge 12 -18 */
    color: var(--ys-input-placeholder);
  }
  .form-switch {
    padding-left: 0;
    .form-check-input {
      margin-left: 0;
    }
  }
  .form-label {
    font-family: var(--ys-label-font);
    color: var(--ys-label-color);
  }
  .form-check-input:checked {
    background-color: var(--ys-primary);
    border-color: var(--ys-primary);
  }
  .form-check-input:focus {
    border-color: var(--ys-primary);
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(214, 51, 132, 0.25);
  }
}
