.panaromic-image-container{
    width:100%;
    height:100%;
    position: absolute;
    left:0;
    top:0;
}
.panaromic-image-screen{
    width:100%;
    height:100%;
    position: absolute;
    left:0;
    top:0;
    z-index:1;
}
.view360-hotspots {
  .hotspot {
    background-color: var(--ys-component-icon-bg);
    width: 50px;
    height: 50px;
    border-radius: 50%;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--ys-component-icon-color);
    font-size: 2rem;
  }
}
.hotspot-icon-hover {
  .hotspot {
    cursor: pointer;
  }
}