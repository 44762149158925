// quiz
.quiz-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: var(--ys-component-overflow);
}
.quiz-content {
  position: relative;
  min-height:100%;
  padding: var(--ys-component-padding);
}
.quiz-answers {
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 2rem;
}
.quiz-answers > li {
  margin: 0.5rem 0;
  padding: 0.5rem;
  position: relative;
  border-radius: 0.25rem;
  font-size: var(--ys-component-font-size);
  color: var(--ys-component-answer);
  background-color: var(--ys-component-answer-bg);
  border-radius: var(--answer-border-radius);
}
.quiz-answers > li:hover {
  background-color: var(--ys-component-answer-bg-hover);
  color: var(--ys-component-answer-hover);
  cursor: pointer;
}
.quiz-answer-alph {
  position: absolute;
  left: 10px;
  top: 10px;
  display: inline-block;
  background-color: $primary;
  color: #fff;
  text-align: center;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  font-family: $headings-font-family;
  line-height: 20px;
}

.quiz-answers > li.selected {
  background-color: #333;
  color: #fff;
  .quiz-answer-alph {
    background-color: $secondary;
  }
}

.quiz-answers > li.selected-correct {
  background-color: var(--ys-component-answer-bg-correct);
  color: var(--ys-component-answer-color-correct);
  .quiz-answer-alph {
    background-color: $secondary;
  }
}
.quiz-answers > li.selected-correct:after {
  font-family: "boxicons";
  content: "\ea41";
  color: #fff;
  position: absolute;
  right: 10px;
  font-size: 2rem;
}
.quiz-answers > li.selected-incorrect {
  background-color: var(--ys-component-answer-bg-incorrect);
  color: var(--ys-component-answer-color-incorrect);
  .quiz-answer-alph {
    background-color: #999;
  }
}
.quiz-answers > li.selected-incorrect:after {
  font-family: "boxicons";
  content: "\ec8d";
  color: #fff;
  position: absolute;
  right: 10px;
  font-size: 1.5rem;
}

.quiz-answers > li.unselected-correct {
  .quiz-answer-alph {
  }
}
.quiz-answers > li.unselected-correct:after {
  font-family: "boxicons";
  content: "\ea41";
  //color:$success;
  position: absolute;
  right: 10px;
  font-size: 1.5rem;
}

.quiz-answers > li.unselected-incorrect {
  //  background-color:#eee;
  .quiz-answer-alph {
    //    background-color:#999;
  }
}

.quiz-answers > li.unselected-incorrect:after {
  content: "";
  color: $danger;
}

.quiz-answers-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 2rem;
  li {
    flex-grow: 1;
    flex-basis: 0;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 40%;
    max-width: calc(50% - 0.5rem);
    margin: 0;
  }
}

.answered {
}

.quiz-pager {
  text-align: center;

  position: relative;
  .quiz-pager-content {
    display: flex;
    align-items: center;
    .indicator-text{
      font-size:0.875rem;
    }
  }
  .quiz-pager-content > div {
    display: inline-block;
    margin-right: 5px;
  }

  .current,
  .complete {
    color: $primary !important;
  }
  .correct,
  .incorrect {
    color: #333;
  }
}
.dd-pager {
  margin-bottom: 40px;
}
.quiz-pager-content {
  position: absolute;
  right: 0;
}
.quiz-pager-content-right {
  position: absolute;
  right: 0;
}
.quiz-pager-content-left {
  position: absolute;
  left: 0;
  right: unset;
}

.quiz-pager-content-center {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  right: unset;
}
.quiz-score,
.questionnaire-score {
  padding: 20px;
  text-align: center;
  font-size: 1.125rem;
}
.quiz-question > div, .quiz-question > div > div:first-child{
  display: inline !important;
}
.quiz-score-total,
.questionnaire-score-total {
  color: var(--ys-component-text-color);
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 2.25rem;
}
.quiz-count {
}
.quiz-divider {
  margin: 5px 0 10px 0;
}
.quiz-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top:10px;
}

.quiz-media {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  img,
  video {
    max-height: 150px;
  }
}
// end quiz//
.quiz-auto-loader {
  height:3px;
  background-color:#333;
  position: relative;
  animation-name: auto-loader;
  animation-duration: 3s !important;
  animation-delay: 0 !important;
  animation-iteration-count: 1 !important;
  --animate-delay: 0 !important;
  --animate-duration: 3s !important;
  --animate-repeat: 1 !important;
  transition: transform 0.2s ease-in !important;
}

@keyframes auto-loader {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

.quiz-content{
  .feedback{
    position:absolute;
    width:80%;
    height:80%;
    left:calc(50% - 40%);
    top:calc(50% - 40%);
    background-color: #fff;
    border-radius: var(--answer-border-radius);
    box-shadow:$box-shadow-sm;
    display:flex;
    gap:10px;
    flex-direction: column;
    padding:20px;
    .feedback-title{
      text-align: center;
      font-size:2rem;
      margin-bottom:10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .feedback-content{
      flex-grow: 1;
      display: flex;
      justify-content: center;
    }
    .feedback-buttons{
      text-align: center;
    }
  }
}