.countdown {
  background-color: rgba(darken($info, 10%), 0.9);
  font-family: var( --ys-font-base);
}
.countdown {
  z-index: 900;
  position: fixed;
  left: 30%;
  top: 30%;
  width: 40%;
  height: 40%;
  color: #fff;
  background-color: rgba($info, 0.95);
  box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.5);
  font-size: 24px;
}
.countdown h1 {
  font-size: 48px;
}
.countdown-content {
  padding: 20px;
  font-size: 24px;
  overflow-y: scroll;
  max-height: 85%;
  text-align: center;
}

.countdown-clock {
    font-family: var( --ys-font-base);
  font-size: 40px;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  background-color: #000;
  padding: 10px;
  color: #fff;
}

@media (max-width: 767px) {
  .countdown {
    width: 94%;
    height: calc(100vh - 150px);
    top: 100px;
    left: 3%;
  }
}
