.chat-adventure {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  .chat-adventure-bg {
    position: absolute;
    top: 0;
    left: 0;
    object-fit:contain;
  }
  .chat-choices-container {
    position: absolute;

    .chat-choices {
      padding: 20px;
    }
  }

  .btn-end-chat {
    position: absolute;
  }
  .chat-choices-container-bottom {
    bottom: 0;
    left: 0;
    width: 100%;

    .btn-end-chat {
      bottom: 10px;
      right: 10px;
    }
  }

  .chat-choices-container-top {
    top: 0;
    left: 0;
    width: 100%;
    .btn-end-chat {
      bottom: 10px;
      right: 10px;
    }
  }

  .chat-choices-container-right {
    right: 0;
    top: 0;
    height: 100%;

    .chat-choices {
      height: 100%;
    }
    .btn-end-chat {
      bottom: 10px;
      right: 10px;
    }
  }

  .chat-choices-container-left {
    left: 0;
    top: 0;
    height: 100%;
    .chat-choices {
      height: 100%;
    }
    .btn-end-chat {
      bottom: 10px;
      right: 10px;
    }
  }

  // speech bubbles

  .sb-tr:before {
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 10px solid var(--ys-component-bubbleBg);
    border-right: 10px solid transparent !important;
    border-top: 10px solid var(--ys-component-bubbleBg);
    border-bottom: 10px solid transparent !important;
    right: -19px;
    top: 6px;
  }

  .sb-tl:before {
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 10px solid transparent !important;
    border-right: 10px solid var(--ys-component-bubbleBg);
    border-top: 10px solid var(--ys-component-bubbleBg);
    border-bottom: 10px solid transparent !important;
    left: -19px;
    top: 6px;
  }

  .sb-bl:before {
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 10px solid var(--ys-component-bubbleBg);
    border-right: 10px solid transparent !important;
    border-top: 10px solid var(--ys-component-bubbleBg);
    border-bottom: 10px solid transparent !important;
    left: 19px;
    bottom: -19px;
  }

  .sb-br:before {
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 10px solid transparent !important;
    border-right: 10px solid var(--ys-component-bubbleBg);
    border-top: 10px solid var(--ys-component-bubbleBg);
    border-bottom: 10px solid transparent !important;
    right: 19px;
    bottom: -19px;
  }
  .chat-choices {
    .sb-tr:before {
      content: "";
      width: 0px;
      height: 0px;
      position: absolute;
      border-left: 10px solid var(--ys-component-bubbleBg);
      border-right: 10px solid transparent !important;
      border-top: 10px solid var(--ys-component-bubbleBg);
      border-bottom: 10px solid transparent !important;
      right: -19px;
      top: 6px;
    }

    .sb-tl:before {
      border-right: 10px solid var(--ys-component-optionBubbleBg);
      border-top: 10px solid var(--ys-component-optionBubbleBg);
    }

    .sb-bl:before {
      border-left: 10px solid var(--ys-component-optionBubbleBg);
      border-top: 10px solid var(--ys-component-optionBubbleBg);
    }

    .sb-br:before {
      border-left: 10px solid var(--ys-component-optionBubbleBg) !important;
      border-bottom: 10px solid var(--ys-component-optionBubbleBg) !important;
    }
  }

  .component-toolbar {
    display: none;
    z-index: 999;
    top: 10px !important;
  }
}
.chat-adventure:hover {
  .component-toolbar {
    display: flex;
  }
}
