.chat-messenger {
  .chatbox {
    width: auto;
    overflow: hidden;
    height: 100%;
    padding: 20px;
    color: var(--ys-component-text-color);
  }

  .chatbox .modal-dialog,
  .chatlist .modal-dialog {
    max-width: 100%;
    margin: 0;
  }

  .msg-head .avatar-name {
    font-size: 150%;
    font-weight: 600;
    line-height: 1.5;
    margin-bottom: 0;
  }

  .msg-head p {
    color: var(--ys-component-text--color);
    font-size: 87.5%;
    font-weight: 400;
    line-height: 1.5;
    text-transform: capitalize;
    margin-bottom: 0;
  }

  .msg-head {
    padding: 15px;
    border-bottom: 1px solid var(--ys-component-divider-color);
    .avatar {
      border-radius: 50%;
      object-fit: cover;
    }
  }

  .msg-body {
    scroll-behavior: smooth;
  }
  .msg-body ul {
    overflow: hidden;
  }

  .msg-body ul li {
    list-style: none;
    margin: 15px 0;
  }

  .msg-body ul li.sender {
    display: block;
    width: 100%;
    position: relative;
  }

  .msg-body ul li.sender:before {
    display: block;
    clear: both;
    content: "";
    position: absolute;
    top: -6px;
    left: -7px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 12px 15px 12px;
    border-color: transparent transparent var(--ys-component-bubbleBg)
      transparent;
    -webkit-transform: rotate(-37deg);
    -ms-transform: rotate(-37deg);
    transform: rotate(-37deg);
  }

  .msg-body ul li.sender p {
    color: var(--ys-component-bubbleColor);
    font-size: 87.5%;
    line-height: 1.5;
    font-weight: 400;
    padding: 15px;
    background: var(--ys-component-bubbleBg);
    display: inline-block;
    border-bottom-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    margin-bottom: 0;
  }

  .msg-body ul li.sender p b {
    display: block;
    color: var(--ys-component-optionBubbleColor);
    font-size: 87.5%;
    line-height: 1.5;
    font-weight: 500;
  }

  .msg-body ul li.reply {
    display: block;
    width: 100%;
    text-align: right;
    position: relative;
  }

  .msg-body ul li.reply:before {
    display: block;
    clear: both;
    content: "";
    position: absolute;
    bottom: 15px;
    right: -7px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 12px 15px 12px;
    border-color: transparent transparent var(--ys-component-optionBubbleBg)
      transparent;
    -webkit-transform: rotate(37deg);
    -ms-transform: rotate(37deg);
    transform: rotate(37deg);
  }

  .msg-body ul li.reply p {
    color: var(--ys-component-optionBubbleColor);
    font-size: 87.5%;
    line-height: 1.5;
    font-weight: 400;
    padding: 15px;
    background: var(--ys-component-optionBubbleBg);
    display: inline-block;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    margin-bottom: 0;
  }

  .msg-body ul li.reply p b {
    display: block;
    color: #061061;
    font-size: 87.5%;
    line-height: 1.5;
    font-weight: 500;
  }

  .msg-body ul li.reply:after {
    display: block;
    content: "";
    clear: both;
  }

  .time {
    display: block;
    color: #000;
    font-size: 75%;
    line-height: 1.5;
    font-weight: 400;
  }

  li.reply .time {
    margin-right: 20px;
  }

  .divider {
    position: relative;
    z-index: 1;
    text-align: center;
  }

  .msg-body h6 {
    text-align: center;
    font-weight: normal;
    font-size: 87.5%;
    line-height: 1.5;
    color: #222;
    background: #fff;
    display: inline-block;
    padding: 0 5px;
    margin-bottom: 0;
  }

  .divider:after {
    display: block;
    content: "";
    clear: both;
    position: absolute;
    top: 12px;
    left: 0;
    border-top: 1px solid #ebebeb;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  .send-box {
    padding: 15px;
    border-top: 1px solid var(--ys-component-divider-color);
  }

  .send-box form {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  .send-box {
    form {
      display: flex;
      width: 100%;
      flex-direction: row;
      align-items: stretch;
      gap: 10px;
      .form-control {
        font-size: 100%;
      }
      .position-relative {
        flex: 1;
      }
      .btn-ys {
        width: 25%;
        min-width: 100px;
      }
    }
  }

  .send-box button i {
    margin-right: 5px;
  }
  .send-box {
    .btn-ys {
      font-size: 100%;
    }
    .form-control {
      background-color: var(--ys-input-bg);
      color: var(--ys-input-color);
      font-family: var(--ys-input-font);
    }

    .form-control:focus {
      border-color: var(--ys-input-focus);
      box-shadow: inset 0 0 0 rgba(0, 0, 0, 0),
        0 0.5rem 1.125rem -0.5rem rgba(0, 0, 0, 0.2);
    }

    .form-control::placeholder {
      color: var(--ys-input-placeholder);
      opacity: 1; /* Firefox */
    }

    .form-control::-ms-input-placeholder {
      /* Edge 12 -18 */
      color: var(--ys-input-placeholder);
    }
  }

  .modal-dialog-scrollable {
    height: 100% !important;
    overflow: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
  }
  .modal-dialog-scrollable::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
  }
  .modal-dialog-scrollable .modal-content {
    max-height: 100%;
    overflow: hidden;
  }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
  }
  .modal-dialog-scrollable .modal-body::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
  }

  .chat-choices {
    position: absolute;
    bottom: 10px;
    left: 0%;
    padding: 20px;
    width: 100%;
    a {
      margin: 0;
    }
    ul {
      padding: 0 20px 20px 20px;
      background-color: #eee;
      box-shadow: $box-shadow;
      border-radius: $border-radius;
    }
    ul li.reply {
      text-align: left;
      p {
        border-radius: 10px;
      }
    }
    ul li.reply:before {
      display: block;
      clear: both;
      content: "";
      position: absolute;
      bottom: 15px;
      left: -7px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 12px 15px 12px;
      border-color: transparent transparent var(--ys-component-optionBubbleBg)
        transparent;
    }
  }

  .typing-indicator {
    &::after {
      height: 10px;
      width: 10px;
      left: -10px;
      bottom: -10px;
    }
    span {
      height: 15px;
      width: 15px;
      float: left;
      margin: 0 1px;
      background-color: #9e9ea1;
      display: block;
      border-radius: 50%;
      opacity: 0.4;
      @for $i from 1 through 3 {
        &:nth-of-type(#{$i}) {
          animation: 1s blink infinite ($i * 0.3333s);
        }
      }
    }
  }

  @keyframes blink {
    50% {
      opacity: 1;
    }
  }

  @keyframes bulge {
    50% {
      transform: scale(1.05);
    }
  }
}
