// animations
@keyframes dropdown-show {
  from {
    transform: scale(0.9);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes popover-show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.anim-open {
  //animation: zoom-in 0.15s ease-in;
  animation: dropdown-show 0.2s;
  transition: all !important;
}
.anim-close {
  animation: zoom-out 0.25s ease-in-out;
  transition: all !important;
}
@keyframes zoom-in {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  75% {
    opacity: 0.75;
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes zoom-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale3d(0.5, 0.5, 1);
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  75% {
    opacity: 0.75;
  }
  100% {
    opacity: 1;
  }
}

// custom animations for animation menu

@keyframes borderGrow {
  from,
  to {
    transform: translate3d(0, 0, 0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translate3d(-10px, 0, 0);
  }

  20%,
  40%,
  60%,
  80% {
    transform: translate3d(10px, 0, 0);
  }
}
.animate__borderGrow {
  .btn-stretch {
    animation-name: borderGrow;
    animation-duration: var(--animate-duration);
    animation-duration: var(--animate-delay);
  }
}

@keyframes float {
  0% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    transform: translateY(0px);
  }
  50% {
    box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2);
    transform: translateY(-20px);
  }
  100% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    transform: translateY(-10px);
  }
}
.animate_hoverFloat:hover {
  transition: box-shadow .5s ease-in-out !important;
  animation-name: float;
  animation-timing-function: ease-in-out;
  animation-delay: 0 !important;
  animation-duration: .75s !important;
  animation-iteration-count: 1 !important;
  --animate-delay: 0  !important;
  --animate-duration: .75s  !important;
  --animate-repeat:1 !important;
  animation-fill-mode: forwards;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.animate__spin {
  animation-name: spin;
  animation-timing-function: linear;
}

// Hover

@keyframes grow {
  0% { transform: scale(1) }
  100% { transform: scale(1.15) }
 }
 .animate_hoverGrow{
  transition: all 0.2s ease-in-out !important;
 }
.animate_hoverGrow:hover {
  transform:scale(1.15);
  animation-delay: 0 !important;
  animation-duration: 0.2s !important;
  animation-iteration-count: 1 !important;
  transition: all 0.2s ease-in-out !important;
  --animate-delay: 0  !important;
  --animate-duration: 0.2s  !important;
  --animate-repeat:1  !important;
}

.animate_hoverGrowSmall{
  transition: all 0.2s ease-in-out !important;
 }
.animate_hoverGrowSmall:hover {
  transform:scale(1.05);
  animation-delay: 0 !important;
  animation-duration: 0.2s !important;
  animation-iteration-count: 1 !important;
  transition: all 0.2s ease-in-out !important;
  --animate-delay: 0  !important;
  --animate-duration: 0.2s  !important;
  --animate-repeat:1  !important;
}

// Shrink
@keyframes shrink {
  0% { transform: scale(1) }
  100% { transform: scale(.85) }
 }
.animate_hoverShrink:hover {
  transform: scale(.85);
  animation-delay: 0 !important;
  animation-duration: 0.2s !important;
  animation-iteration-count: 1 !important;
  transition: all 0.2s ease-in-out !important;
  --animate-delay: 0  !important;
  --animate-duration: 0.2s  !important;
  --animate-repeat:1  !important;
}


.animate_hoverShrinkSmall:hover {
  transform: scale(.95);
  animation-delay: 0 !important;
  animation-duration: 0.2s !important;
  animation-iteration-count: 1 !important;
  transition: all 0.2s ease-in-out !important;
  --animate-delay: 0  !important;
  --animate-duration: 0.2s  !important;
  --animate-repeat:1  !important;
}
// shake
@keyframes tilt-shaking {
  0% { transform: rotate(0deg); }
  25% { transform: rotate(5deg); }
  50% { transform: rotate(0eg); }
  75% { transform: rotate(-5deg); }
  100% { transform: rotate(0deg); }
}
.animate_hoverShakeTilt:hover  {
  animation-name: tilt-shaking;
  animation-duration: 0.2s !important;
  animation-delay: 0 !important;
  animation-iteration-count: infinite !important;
  transition: transform 0.2s ease-in-out !important;
  --animate-delay: 0  !important;
  --animate-duration: 0.2s  !important;
  --animate-repeat:infinite !important;
}

@keyframes horizontal-shaking {
  0% { transform: translateX(0) }
  25% { transform: translateX(5px) }
  50% { transform: translateX(-5px) }
  75% { transform: translateX(5px) }
  100% { transform: translateX(0) }
 }
 .animate_hoverShakeHorizontal:hover {
   animation-name: horizontal-shaking;
   animation-duration: 0.2s !important;
   animation-delay: 0 !important;
   animation-iteration-count: infinite !important;
   transition: transform 0.2s ease-in-out !important;
   --animate-delay: 0  !important;
   --animate-duration: 0.2s  !important;
   --animate-repeat:infinite !important;
 }

@keyframes vertical-shaking {
  0% { transform: translateY(0) }
  25% { transform: translateY(5px) }
  50% { transform: translateY(-5px) }
  75% { transform: translateY(5px) }
  100% { transform: translateY(0) }
 }
 .animate_hoverShakeVertical:hover {
   animation-name: vertical-shaking;
   animation-duration: 0.2s;
   animation-delay: 0 !important;
   animation-iteration-count: infinite !important;
   transition: transform 0.2s ease-in-out !important;
   --animate-delay: 0  !important;
   --animate-duration: 0.2s  !important;
   --animate-repeat:infinite !important;
 }



@keyframes rotate-left {
  0% { transform: rotate(0deg) }
  25% { transform: rotate(-5deg) }
  100% { transform: rotate(-15deg) }
 }
 .animate_hoverRotateLeft:hover {
   animation-name: rotate-left;
   animation-duration: 0.2s !important;
   animation-delay: 0 !important;
   animation-iteration-count: 1 !important;
   transition: transform 0.2s ease-in-out !important;
   --animate-delay: 0  !important;
   --animate-duration: 0.2s  !important;
   --animate-repeat:1 !important;
   animation-fill-mode: forwards;
 }

 @keyframes rotate-right {
   0% { transform: rotate(0deg) }
   25% { transform: rotate(5deg) }
   100% { transform: rotate(15deg) }
  }
  .animate_hoverRotateRight:hover {
    animation-name: rotate-right;
    animation-duration: 0.2s;
    animation-delay: 0 !important;
    animation-iteration-count: 1 !important;
    transition: transform 0.2s ease-in-out !important;
    --animate-delay: 0  !important;
    --animate-duration: 0.2s  !important;
    --animate-repeat:1 !important;
    animation-fill-mode: forwards;
  }

  .bring-to-front:hover{
    z-index:999999999999999 !important;
  }

// hover anims
.hover-grow {
  transition: all 0.2s ease-in-out;
}
.hover-grow:hover {
  transform: scale(1.05);
}
